import LabsWandhInputForm from '@/components/labs/wandh/input-form.vue';
import { useStore } from '@/store/useStore';
export default defineComponent({
  components: {
    LabsWandhInputForm: LabsWandhInputForm
  },
  layout: 'labs',
  setup: function setup() {
    // TODO リセットしないほうがいいケースもありうるかも、途中で閉じちゃった時とか？
    onMounted(function () {
      var store = useStore();
      store.commit('resetLabsWandhStreamParams');
    });
  }
});